import moment from 'moment';
import { alertController, toastController } from '@ionic/vue';
import { useStore } from 'vuex';
import { computed } from "vue";

import config from '@/config';
import { Browser } from '@capacitor/browser';
import { useI18n } from 'vue-i18n';

export function utils() {
  const { t } = useI18n();
  const store = useStore();
  const currUser = computed(() => store.state.user);

  const openBrowser = async (url: any) => {
    await Browser.open({ url, toolbarColor: config.primaryColor });
  }

  const BOOKING_STATUSES = {
    pending: 'pending',
    confirm: 'confirmed',
    ongoing: 'ongoing',
    complete: 'completed',
    cancel: 'cancelled',
    fail: 'failed',
  }
  const getBookingStatusColor = (status: string) => {
    if ([BOOKING_STATUSES.fail, BOOKING_STATUSES.cancel].includes(status)) return 'medium';
    if (status == BOOKING_STATUSES.pending) return 'warning';
    if (status == BOOKING_STATUSES.confirm) return 'tertiary';
    if (status == BOOKING_STATUSES.ongoing) return 'primary';
    if (status == BOOKING_STATUSES.complete) return 'success';
    return 'light';
  }
  const ORDER_STATUSES = {
    processing: 'processing',
    pending: 'pending', // pending for payment
    hold: 'hold',
    fail: 'failed',
    complete: 'completed',
    cancel: 'cancelled',
  }
  const getOrderStatusColor = (status: string) => {
    if ([ORDER_STATUSES.fail, ORDER_STATUSES.cancel].includes(status)) return 'medium';
    if (status == ORDER_STATUSES.pending) return 'danger';
    if (status == ORDER_STATUSES.processing) return 'tertiary';
    if (status == ORDER_STATUSES.hold) return 'warning';
    if (status == ORDER_STATUSES.complete) return 'success';
    return 'light';
  }

  const formatDate = (date: any, dateFormat: any = 'YYYY/MM/DD HH:mm') => moment(new Date(date)).format(dateFormat);

  const getRelativeDate = (date: any) => moment(new Date(date)).fromNow();

  const presentAlert = async (message: any, header: any = null) => {
    const obj: any = {
      header,
      message,
      buttons: ['OK']
    }
    const alert = await alertController.create(obj);
    return await alert.present();
  };
  const presentToast = async (message: any, duration = 5000, position: any = 'bottom') => {
    const toast = await toastController.create({
      message,
      duration,
      position,
    });
    toast.present();
  }

  const isPostAuthorBanned = (post: any) => {
    return currUser.value.relatedBannedUsers ?
            post.userId != currUser.value.id && currUser.value.relatedBannedUsers.includes(post.userId)
            : false;
  }
  
  const focusInputField = (refEl: any) => {
    refEl.$el.setFocus();
  }

  return {
    BOOKING_STATUSES,
    getBookingStatusColor,
    ORDER_STATUSES,
    getOrderStatusColor,
    formatDate,
    getRelativeDate,
    presentAlert,
    presentToast,
    isPostAuthorBanned,
    focusInputField,
    openBrowser,

    presentPrompt: async (header: any = "", message: any = "", callback: any, cssClass = "") => {
      const alert = await alertController.create({
        header,
        message,
        cssClass,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    },
  };
}